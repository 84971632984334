<template>
  <div class="home">
    <van-nav-bar
      title="标题"
      left-text="返回"
      right-text="按钮"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-empty description="我的" />
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'Home',
  data() {
    return {
      active: 'home',
    }
  },
  methods: {
    onClickLeft() {
      Toast('返回')
    },
    onClickRight() {
      Toast('按钮')
    },
  },
}
</script>
